





import { Component, Vue } from "vue-property-decorator";

@Component
export default class Main extends Vue {
  private created(): void {
    this.$router.push({ name: "swap" });
  }
}
